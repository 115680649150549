import React, { useEffect, useRef, useState } from "react";
import Layout from "../include/layout";
import "../../scss/pages/refer.scss";
import Widthcontainer from "../customcomponents/widthcontainer";
import { refferal } from "../../data/protectedapi";
import { get_refer_component, get_refer_banner } from "../../data/api";
import MessageModal from "../customcomponents/messageModal";
import Token from "../../utils/useToken";
import Loading from "../customcomponents/loading";
import HeroBanner from "../customcomponents/herobanner";
import SeoTags from "../../utils/seoTags";
import { required } from "khalti-checkout-web";
import Captcha from "../include/captcha";

const Refer = () => {
   const [name, setName] = useState("");
   const [phone, setPhone] = useState("");
   const [email, setEmail] = useState("");
   const [messageModal, setMessageModal] = useState(false);
   const [kycModal, setKycModal] = useState(false);
   const [referData, setReferData] = useState("");
   const [loading, setLoading] = useState(true);
   const [referBanner, setReferBanner] = useState("");
   const [seoData, setSeoData] = useState("");
   const [cartupdate, setCartupdate] = useState(false);
   const [referError, setReferError] = useState(false);
   const [referErrorMessage, setReferErrorMessage] = useState("");
   const reRef = useRef();

   const handleSubmit = async (e) => {
      e.preventDefault();
      let number = phone;
      if (phone?.length > 10) {
         if (phone?.charAt(0) === "0") {
            number = phone.substring(1);
         } else if (phone?.slice(0, 4) === "+977") {
            number = phone?.slice(4, 18);
         }
      }
      const captchaToken = await reRef.current.executeAsync();
      // console.log("Token", captchaToken);
      const response = await refferal(name, number, email, captchaToken);
      if (response.status === 201) {
         setMessageModal(true);
      } else {
         setReferError(true);
         setReferErrorMessage(response?.response?.data?.errors[0]?.messages[0]);
      }
   };

   //get user details
   const { userDetails } = Token();

   useEffect(() => {
      //get refer data
      const getReferData = async () => {
         const response = await get_refer_component();
         const res = await get_refer_banner();
         setReferBanner(res?.data?.banners);
         setSeoData(res?.data?.seo);
         setReferData(response?.data?.data);
         setLoading(false);
      };
      getReferData();
   }, [loading]);

   useEffect(() => {
      if (
         referData === "" ||
         referData === undefined ||
         referBanner === "" ||
         referBanner === undefined
      ) {
         setLoading(true);
      } else {
         setLoading(false);
      }
   }, []);

   useEffect(() => {
      if (userDetails?.is_kyc_verified === false) {
         setKycModal(true);
      } else {
         setKycModal(false);
      }
   }, [userDetails]);

   return loading ? (
      <Loading />
   ) : (
      <div>
         <Captcha reRef={reRef} />
         {SeoTags("Refer | DishHome Nepal", "DishHome Nepal", "DishHome Nepal")}
         <Layout cartupdate={cartupdate} setCartupdate={setCartupdate}>
            <MessageModal
               show={messageModal}
               setShow={setMessageModal}
               heading="Your Referral is Submmited"
               message="Thank you! Our team will contact the person shortly. And if they accept to install DishHome, you will get the reward as mentioned."
               buttonText="Go to Home Page"
               link="/"
            />
            <MessageModal
               type="error"
               show={kycModal}
               setShow={setKycModal}
               heading="Please complete your profile information to access this feature."
               buttonText="Home Page"
               button2={"Complete Profile"}
               link="/"
               link2="/dashboard?type=1"
               isNavigate={true}
            />
            <MessageModal
               type="error"
               show={referError}
               setShow={setReferError}
               heading="Something went wrong!"
               message={referErrorMessage}
               buttonText="OK"
            />
            <Widthcontainer className={"referBanner__widthContainer"}>
               {seoData &&
                  Object.entries(seoData).map(([key, value]) => {
                     if (value?.page === "refer") {
                        return SeoTags(
                           value?.title,
                           value?.title,
                           "",
                           value?.keyword,
                           "",
                           `${process.env.REACT_APP_PUBLIC_URL}`,
                        );
                     } else {
                        return null;
                     }
                  })}
               {referBanner &&
                  Object.entries(referBanner).map(([key, value]) => {
                     if (value.page_location === "refer_top") {
                        return <HeroBanner data={value} />;
                     } else return null;
                  })}{" "}
               <div style={{ height: "28px" }}></div>
               <div className="referCardContainer fixed-width75">
                  {referData &&
                     Object.entries(referData?.top_info).map(([key, value]) => {
                        return (
                           <div className="referCard">
                              <img
                                 src={`${process.env.REACT_APP_FILE_URL}/${value.image}`}
                                 alt=""
                              />
                              <h2
                                 className="referCard__heading"
                                 // style={
                                 //    // { color: "#4BD9B1" }
                                 //    value.color === "blue"
                                 //       ? { color: "#4BD9B1" }
                                 //       : key === 2
                                 //       ? { color: "#F03E45" }
                                 //       : {}
                                 // }
                              >
                                 {value.title}
                              </h2>
                              <p className="referCard__text">{value.description}</p>
                           </div>
                        );
                     })}
               </div>
               {/* <div className="referCard">
               <img src={refer2} alt="" />
               <h2 className="referCard__heading" style={{ color: "#4BD9B1" }}>
                  CONFIRM INSTALLATION
               </h2>
               <p className="referCard__text">
                  Our staff will verify the references made and schedule a date for the new
                  installation.
               </p>
            </div>
            <div className="referCard">
               <img src={refer3} alt="" />
               <h2 className="referCard__heading" style={{ color: "#F03E45" }}>
                  GET FREE INTERNET
               </h2>
               <p className="referCard__text">
                  You will be automatically rewarded with one month's FREE Internet & NETTV package
                  after successful installation and complete payment by the referred individual.
               </p>
            </div> */}
               <div className="referContent fixed-width75 mb-4">
                  <div className="referForm">
                     <p className="referForm__heading">Refer a Friend</p>
                     <form className="referForm__form" onSubmit={(e) => handleSubmit(e)}>
                        <label htmlFor="name">Full Name</label>
                        <input
                           type="text"
                           name="name"
                           className={"Name_Input"}
                           placeholder=""
                           minlength={"2"}
                           required={required}
                           maxLength={50}
                           value={name}
                           onChange={(e) => {
                              if (e?.nativeEvent?.data) {
                                 if (e?.nativeEvent?.data?.toString()?.match("[A-Za-z \b]")) {
                                    setName(e?.target?.value);
                                 }
                                 if (e?.nativeEvent?.data === null) {
                                    setName(e?.target?.value);
                                 }
                              } else {
                                 setName(e?.target?.value);
                              }
                           }}
                        />
                        <label htmlFor="number">Phone Number</label>
                        <input
                           type="tel"
                           name="number"
                           className={"Phone_Input"}
                           placeholder=""
                           value={phone}
                           minLength={10}
                           maxLength={14}
                           onChange={(e) => {
                              // console.log("phone", isNaN(e.nativeEvent?.data));
                              if (e?.nativeEvent?.data) {
                                 if (e?.nativeEvent?.data?.toString()?.match("[0-9+\b]")) {
                                    setPhone(e.target.value);
                                 }
                                 if (e?.nativeEvent?.data === null) {
                                    setPhone(e.target.value);
                                 }
                              } else {
                                 setPhone(e.target.value);
                              }
                           }}
                           required
                        />
                        <label htmlFor="email">Email</label>
                        <input
                           type="email"
                           name="email"
                           className={"Email_Input"}
                           placeholder=""
                           minlength={"6"}
                           maxLength={60}
                           required={required}
                           value={email}
                           onChange={(e) => setEmail(e.target.value)}
                           pattern={"[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,4}$"}
                        />
                        <button className="referButton" type="submit">
                           Refer
                        </button>
                     </form>
                  </div>
                  <div className="referTermAndCondition">
                     <p className="referTermAndCondition__heading">Terms & Conditions</p>
                     <div dangerouslySetInnerHTML={{ __html: referData.terms }}></div>
                     {/* <p className="referTermAndCondition__text">
                     To be eligible referral should be an existing DH Fibernet customer and should
                     refer within DH Fibernet available area (network available).
                  </p>
                  <p className="referTermAndCondition__text">
                     The referrer should refer internet package of 12 months, 6 months, 3 months & 1
                     month to get the benefit.
                  </p>
                  <p className="referTermAndCondition__text">
                     The referred customer must be installed within 15 days of being referred for
                     the referral offer to be valid.
                  </p>
                  <p className="referTermAndCondition__text">
                     The referral will not be valid if the customer being referred has already
                     booked a connection with any DishHome dealers.
                  </p>
                  <p className="referTermAndCondition__text">
                     First, come first priority, if the same customer is referred by two or more
                     clients, the first referee shall be eligible based on referred date and time.
                  </p>
                  <p className="referTermAndCondition__text">
                     DishHome reserves the right to modify/stop/cancel the scheme without notice as
                     it sees fit and shall not be liable for any such modification/termination.
                  </p> */}
                  </div>
               </div>
            </Widthcontainer>
         </Layout>
      </div>
   );
};
export default Refer;
